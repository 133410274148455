import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { Lightbox, AddStudent, ConfirmDelete } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { ClassModel } from "@/models";
import { Common } from "../Common";

type response = { [key: string]: unknown };

@Component<AdminClassStudentController>({
    components: {
        SideNav, Lightbox, AddStudent, ConfirmDelete
    }
})
export default class AdminClassStudentController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private studentList: object = {};
    private clid = this.$route.query.clid as string;
    private num = 0;
    private pageHtml = "";
    private stid = 0;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private addStudentLB = {
        showLB: false,
        studentList: [],
    }

    public created() {
        this.getStudentList();
    }

    private async getStudentList() {
        const item: { [key: string]: string } = {
            clid: this.clid,
            page: this.$route.query.page as string,
            token: this.token,
        }
        const data = await ClassModel.getStudentListData(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.studentList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }

    private openAddStudentLB() {
        this.addStudentLB.showLB = true;
        this.getAddStudentList('', '');
    }

    private async getAddStudentList(name: string, phone: string) {
        const item: { [key: string]: string } = {
            clid: this.clid,
            name: name,
            phone: phone,
            token: this.token,
        }
        const data = await ClassModel.getAddStudentList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.addStudentLB.studentList = data.list as [];
    }

    private closeAddStudentLB() {
        this.addStudentLB.showLB = false;
    }

    private async addStudent(student: []) {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item: { [key: string]: unknown } = {
                clid: this.clid,
                student: student,
                token: this.token,
            }
            this.errNo = await ClassModel.addStudent(item);
            this.addStudentLB.showLB = false;
            this.showLB(this.errNo);
        }
    }

    private delStudent(id: number) {
        this.$emit("openConfirmDelete", true);
        this.stid = id;
    }

    private async deleteStudent() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                clid: this.clid,
                stid: this.stid,
                token: this.token,
            }
            this.errNo = await ClassModel.delStudent(item);
            this.$emit("closeConfirmDelete", false);
            this.showLB(this.errNo);
        }
    }

    private showLB(errNo: number) {
        Common.checkToken(errNo);
        this.openLB = true;
        this.doubleClick = false;
        this.errorMsg = ErrorMessage[errNo];
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0); //reload page
        }
    }
}
